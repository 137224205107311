<h1 mat-dialog-title class="title-error-dialog">{{ dialogTitle }}</h1>
<div class="error-dialog" mat-dialog-content>
  <ul>
    <li *ngFor="let error of errorList">
      {{error}}
    </li>
    <li *ngIf="!errorList">
      Unexpected error
    </li>
  </ul>
</div>
<div mat-dialog-actions>
  <button type="button" mat-stroked-button [mat-dialog-close]>Close</button>
</div>
