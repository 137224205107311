import { Injectable } from '@angular/core';
import { FileSaverOptions } from 'file-saver';
import { FileSaverService } from 'ngx-filesaver';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

type MimeType = 'text/csv' | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

@Injectable({
  providedIn: 'root',
})
export class FileExporterService {
  protected mimeType: MimeType;
  protected data: Blob;
  options: FileSaverOptions = {
    autoBom: false,
  };

  constructor(private fileSaverService: FileSaverService) {}

  setMimeType(type: MimeType) {
    this.mimeType = type;
  }

  setData(data: any) {
    this.data = data;
  }

  download(name): Observable<File> {
    const filename = this.validateFilename(name);
    const file = new File([this.data], filename, { type: this.mimeType });

    return of(file).pipe(delay(1000));
  }

  save(name) {
    const filename = this.validateFilename(name);
    const fileType = this.fileSaverService.genType(filename);
    const blob = new Blob([this.data], { type: fileType });

    this.fileSaverService.save(blob, filename, undefined, this.options);
  }

  validateFilename(name): string {
    const parts = name.toString().split('.');
    const ext = parts.length > 1 ? parts.slice('-1').pop() : this.setFileExtension(this.mimeType);

    return parts[0] + '.' + ext;
  }

  private setFileExtension(type: MimeType) {
    return type === 'text/csv'
      ? 'csv'
      : type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ? 'xlsx'
      : undefined;
  }
}
