import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { XpoAuthenticationGuard } from '@xpo/ngx-auth';
import { NotAuthorizedComponent } from './dialogs/not-authorized/not-authorized.component';
import { AppRoutes } from './shared/enums/app-routes.enum';
import { NotAuthorizedRedirectGuard } from './shared/guards/not-authorized-redirect/not-authorized-redirect.guard';

const routerDefinitions: Routes = [
  {
    path: AppRoutes.NOT_AUTHORIZED_PAGE,
    component: NotAuthorizedComponent,
    canActivate: [XpoAuthenticationGuard, NotAuthorizedRedirectGuard],
  },
  /* {
    path: AppRoutes.LANDING_PAGE,
    loadChildren: 'app/landing-page/landing-page.module#LandingPageModule',
    // FOR SSO
    canLoad: [XpoAuthenticationGuard],
  },*/
  // ADD ANY NEW ROUTE / PAGE HERE
  {
    path: AppRoutes.OFF_BILL_INCENTIVE,
    loadChildren: () => import('./obi/obi.module').then((m) => m.ObiModule),
    // FOR SSO
    canActivate: [XpoAuthenticationGuard],
  },
  {
    path: AppRoutes.OBI_AGREEMENT,
    loadChildren: () => import('./agreement/agreement.module').then((m) => m.AgreementModule),
    // FOR SSO
    canActivate: [XpoAuthenticationGuard],
  },
  {
    path: '**',
    redirectTo: `/${AppRoutes.OBI_AGREEMENT}/${AppRoutes.LIST_PAGE}`,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routerDefinitions, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
