import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { XpoColors } from '../../models/xpo-colors';

export interface StatusIndicatorCellRendererParams extends ICellRendererParams {
  statusColor: string;
  statusText: string;
}

@Component({
  selector: 'status-cell-renderer',
  templateUrl: './status-cell-renderer.component.html',
  styleUrls: ['./status-cell-renderer.component.scss'],
})
export class StatusCellRendererComponent implements ICellRendererAngularComp {
  isLabel: boolean = true; // default - true
  statusColor: string = XpoColors.XPO_TRANSPARENT; // default - transparent
  tooltipText: string = null;
  statusText: string = 'test';

  constructor() {}

  agInit(params: StatusIndicatorCellRendererParams): void {
    this.statusText = params.data.statusText;
    this.statusColor = params.data.statusColor;
  }

  refresh(): boolean {
    return false;
  }
}
