import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { Observable } from 'rxjs';
import { NotificationMenuItem } from '../../../models/notification-menu-item.model';
import { NotificationStateService } from '../../../services/notification-state.service';
import { ObiApiService } from '../../../services/obi-api.service';
import { ErrorDialogComponent } from '../../dialog/error-dialog';
import { Util } from '../../enums/util.enum';

@Component({
  selector: 'app-notification-menu',
  templateUrl: './notification-menu.component.html',
  styleUrls: ['./notification-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class NotificationMenuComponent implements OnInit {
  notifications$: Observable<NotificationMenuItem[]> = this.notificationStateService.notifications$;
  notifications = [];
  notificationColumns: string[] = ['title', 'customer', 'status', 'actions'];
  constructor(
    private dialog: MatDialog,
    private snackbar: XpoSnackBar,
    private obiService: ObiApiService,
    private cdr: ChangeDetectorRef,
    private notificationStateService: NotificationStateService,
  ) {
    this.notifications$.subscribe((notifications) => {
      this.notifications = notifications;
    });
    this.cdr.markForCheck();
  }

  ngOnInit() {}

  retry(notification: NotificationMenuItem) {
    const data = notification.data;
    this.obiService.updateIncentiveHeaderDetail(data, data.obiIncentiveId, Util.retry).subscribe(
      (response) => {
        this.snackbar.info('Retry request sent successfully!');
        this.notificationStateService.pushGridUpdate(true);
      },
      (error) => {
        this.showErrorDialog(error);
      },
    );
  }

  showErrorDialog(error: any): void {
    this.dialog.open(ErrorDialogComponent, { maxWidth: '600px', data: error });
  }
}
