/**
 * Export ngx-ltl-core color definitions to make them accessible outside scss files.
 * https://tcts3-app.ltl.xpo.com/appjs/ngx-ltl-core-docs/colors
 */

export class XpoColors {
  // XPO Brand Colors
  static readonly XPO_BRAND_RED = '#CC0000'; // $xpo-brand-red or $xpo-brand-red--900
  static readonly XPO_BRAND_RED_800 = '#E4210E'; // $xpo-brand-red--800
  static readonly XPO_BRAND_RED_700 = '#F32A13'; // $xpo-brand-red--700
  static readonly XPO_BRAND_RED_600 = '#FF3317'; // $xpo-brand-red--600
  static readonly XPO_BRAND_RED_500 = '#FF391C'; // $xpo-brand-red--500
  static readonly XPO_BRAND_RED_300 = '#FF7E60'; // $xpo-brand-red--300
  static readonly XPO_BRAND_RED_200 = '#FFA48E'; // $xpo-brand-red--200
  static readonly XPO_BRAND_RED_50 = '#FCE8E6'; // $xpo-brand-red--50

  static readonly XPO_BRAND_DARK_GREY = '#414A4C'; // $xpo-brand-dark-grey
  static readonly XPO_BRAND_MEDIUM_GREY = '#848484'; // $xpo-brand-medium-grey
  static readonly XPO_LIGHT_GREY = '#DEDEDE'; // $xpo-light-grey

  // Black And Grey
  static readonly XPO_BLACK = '#000000'; // $xpo-black
  static readonly XPO_GREY_975 = '#212121'; // $xpo-grey--975
  static readonly XPO_GREY_950 = '#333333'; // $xpo-grey--950
  static readonly XPO_GREY_900 = '#4A4A4A'; // $xpo-grey--900
  static readonly XPO_GREY_700 = '#6F6F6F'; // $xpo-grey--700
  static readonly XPO_GREY_350 = '#BDBDBD'; // $xpo-grey--350
  static readonly XPO_GREY_300 = '#D8D8D8'; // $xpo-grey--300
  static readonly XPO_GREY_80 = '#F6F6F6'; // $xpo-grey--80
  static readonly XPO_GREY_50 = '#FAFAFA'; // $xpo-grey--50
  static readonly XPO_WHITE = '#FFFFFF'; // $xpo-white

  // Transactional Cells
  static readonly XPO_GREY_150 = '#ECEFF1'; // $xpo-grey--150
  static readonly XPO_BLUEGREY_700 = '#CFD8DC'; // $xpo-blueGrey--700

  // Links, Buttons, Tabset And Tabpills
  static readonly XPO_BLUE_350 = '#2196F3'; // $xpo-blue--350
  static readonly XPO_BLUE_800 = '#1976D2'; // $xpo-blue--800
  static readonly XPO_BLUE_850 = '#0D47A1'; // $xpo-blue--850

  // Error State
  static readonly XPO_RED_50 = '#FCEEEB'; // $xpo-red--50
  static readonly XPO_RED_150 = '#D32F2F'; // $xpo-red--150
  static readonly XPO_RED_200 = '#B71C1C'; // $xpo-red--200

  // Success State
  static readonly XPO_GREEN_50 = '#F1F9F1'; // $xpo-green--50
  static readonly XPO_GREEN_350 = '#00C853'; // $xpo-green--350
  static readonly XPO_GREEN_450 = '#4CAF50'; // $xpo-green--450
  static readonly XPO_GREEN_600 = '#388E3C'; // $xpo-green--600

  // Warning State
  static readonly XPO_YELLOW_350 = '#FFD600'; // $xpo-yellow--350
  static readonly XPO_ORANGE_350 = '#FF6D00'; // $xpo-orange--350
  static readonly XPO_TIGER_500 = '#F33F0E'; // $xpo-tiger--500
  // static readonly CUSTOM_ORANGE = '#FF9903'

  // Info State
  static readonly XPO_BLUE_700 = '#0091EA'; // $xpo-blue--700

  // Accent Colors
  static readonly XPO_PURPLE_700 = '#6200EA'; // $xpo-purple--700
  static readonly XPO_PINK_700 = '#C51162'; // $xpo-pink--700

  static readonly XPO_BLUE_825 = '#304FFE'; // $xpo-blue--825
  static readonly XPO_BLUE_200 = '#E4EFFA'; // $xpo-blue--200

  static readonly XPO_ACCENT_RED_100 = '#FFC8BA'; // $xpo-accent-red--100
  static readonly XPO_ACCENT_RED_400 = '#FF5E3D'; // $xpo-accent-red--400

  static readonly XPO_YELLOW_200 = '#FFF9C4'; // $xpo-yellow--200

  static readonly XPO_GREEN_200 = '#A4E59B'; // $xpo-green--200
  static readonly XPO_TEAL_700 = '#00BFA5'; // $xpo-teal--700

  static readonly XPO_TRANSPARENT = 'rgba(0,0,0,0)'; // transparency = 100%

  // Athena Heatmap colors - colorblind friendly - XPO - Palette 2
  static readonly XPO_LIGHT_RED = '#F7F7F7';
  static readonly XPO_PINE_100 = '#B0E8A8';
  static readonly XPO_PINE_700 = '#338E25';
  static readonly XPO_HEATMAP_PINK = '#E8B9D8';
  static readonly XPO_HEATMAP_HOTPINK = '#C03B88';

  // Athena Heatmap colors - colorblind friendly - IBM - Palette 3
  static readonly XPO_PINE_200 = '#98E18D';
  static readonly XPO_HEATMAP_BLUE = '#648FFF';
  static readonly XPO_HEATMAP_PURPLE = '#785EF0';
  static readonly XPO_HEATMAP_YELLOW = '#FFB000';
  static readonly XPO_HEATMAP_ORANGE = '#FE6100';
  static readonly XPO_HEATMAP_MAGENTA = '#DC267F';
}
