import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'boolean-cell-renderer',
  templateUrl: './boolean-cell-renderer.component.html',
  styleUrls: ['./boolean-cell-renderer.component.scss'],
})
export class BooleanCellRendererComponent implements ICellRendererAngularComp {
  value: boolean;
  paramValue: boolean;
  iconName: string = '';

  constructor() {}

  agInit(params: ICellRendererParams): void {
    this.paramValue = params.value;
    this.updateValue(this.paramValue);
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  updateValue(value: any): void {
    this.value = value ? this.checkForTrue(value) : null;
    this.iconName = this.value ? 'checkmark' : 'close';
  }

  checkForTrue(value: any): boolean {
    if (typeof value === 'boolean') {
      return value;
    }
    const stringValue = value.toString().toLowerCase();
    return (
      stringValue === 'true' ||
      stringValue === '1' ||
      stringValue === 'on' ||
      stringValue === 'yes' ||
      stringValue === 'y' ||
      stringValue === 'enabled' ||
      stringValue === 'active'
    );
  }

  shouldDisplayIcon(): boolean {
    return this.paramValue !== null;
  }
}
