import { EventEmitter, Injectable, Output } from '@angular/core';
import { Router } from '@angular/router';
import {
  Agreement,
  BulkCreateProgramCustomerLocationsPath,
  BulkCreateProgramCustomerLocationsRqst,
  BulkProcessStatus,
  CreateAgreementRqst,
  CreateProgramRqst,
  CustomerAgreement,
  DeleteAgreementPath,
  DeleteProgramPath,
  GetCustomerAgreementPath,
  GetCustomerAgreementResp,
  GetProgramPath,
  GetProgramResp,
  Incentive,
  ListCustomerAgreementLocationsResp,
  ListCustomerAgreementsQuery,
  ListCustomerAgreementsResp,
  ListCustomerIncentiveHistoryPath,
  ListCustomerIncentiveHistoryQuery,
  ListCustomerIncentiveHistoryResp,
  ListCustomerProgramLocationsPath,
  ListCustomerProgramLocationsResp,
  OffBillIncentiveApiService,
  Program,
  ProgramCustomerLocation,
  UpdateAgreementPath,
  UpdateAgreementRqst,
  UpdateProgramPath,
  UpdateProgramRqst,
} from '@xpo-ltl/sdk-offbillincentive';
import moment from 'moment-timezone';
import { from, Observable } from 'rxjs/index';
import { map, shareReplay } from 'rxjs/internal/operators';
import { Location } from '../models/location.model';
import { AppRoutes } from '../shared/enums/app-routes.enum';
import { Util } from '../shared/enums/util.enum';
import { FormatDateService } from './format-date.service';
import { HelperService } from './helper.service';

@Injectable({
  providedIn: 'root',
})
export class AgreementListService {
  @Output() deleteActionEmitter$ = new EventEmitter<boolean>();
  private obiAgreementListCache$: Observable<Array<CustomerAgreement>>;
  queryParams = new ListCustomerAgreementsQuery();
  queryParamsCache = new ListCustomerAgreementsQuery();
  expireCache = false;
  agreementLocationCache = new Observable<ListCustomerAgreementLocationsResp>();
  activeFilters: boolean = false;
  filters: any = new ListCustomerAgreementsQuery();
  bulkResults: Array<BulkProcessStatus> = [];
  periodBeginDate: Date;
  periodEndDate: Date;
  obiIncentiveId: string;
  obiIncentiveNbr: string;

  get getPeriodEndDate(): Date {
    return this.periodEndDate;
  }

  get getPeriodBeginDate(): Date {
    return this.periodBeginDate;
  }

  constructor(
    private obiApiService: OffBillIncentiveApiService,
    private dateService: FormatDateService,
    private router: Router,
    private helperService: HelperService,
    private formatDateService: FormatDateService
  ) {}

  deleteActionEmitter() {
    this.deleteActionEmitter$.next(true);
  }

  buildProgram(program: Program, data: any): Program {
    program.aggregatePeriodCd = data.aggregatePeriodCd;
    program.combinedLocationInd = data.combinedLocationInd;
    program.deferredDiscountInd = data.deferredDiscountInd;
    program.deferredDiscountPercentage = data.deferredDiscountPercentage;
    program.inboundCollectCd = data.inboundCollectCd ? Util.shortYes : Util.shortNo;
    program.inboundCollectRate = data.inboundCollectRate;
    program.inboundPrepaidCd = data.inboundPrepaidCd ? Util.shortYes : Util.shortNo;
    program.inboundPrepaidRate = data.inboundPrepaidRate;
    program.maxDaysToPay = data.maxDaysToPay ?? 0;
    program.maxDiscountPercentage = data.maxDiscountPercentage ?? 0;
    program.maxDiscountRevenueAmount = data.maxDiscountRevenueAmount ?? 0;
    program.outboundCollectCd = data.outboundCollectCd ? Util.shortYes : Util.shortNo;
    program.outboundCollectRate = data.outboundCollectRate;
    program.outboundPrepaidCd = data.outboundPrepaidCd ? Util.shortYes : Util.shortNo;
    program.outboundPrepaidRate = data.outboundPrepaidRate;
    program.payOnCd = data.payOnCd;
    program.programEffectiveDate = this.dateService.formatDatePicker(data.programEffectiveDate);
    program.programExpiryDate = this.dateService.formatDatePicker(data.programExpiryDate);
    program.qualifyOnCd = data.qualifyOnCd;
    program.renewProgramInd = data.renewProgramInd;
    program.spottedTrailerInd = data.spottedTrailerInd;
    program.thirdPartyCd = data.thirdPartyCd ? Util.shortYes : Util.shortNo;
    program.thirdPartyRate = data.thirdPartyRate;
    program.verifyShipmentPaidInd = data.verifyShipmentPaidInd;
    program.interStateInd = data.interStateInd || false;
    program.intraStateInd = data.intraStateInd || false;
    program.canadianInd = data.canadianInd || false;
    program.interStateAmcAmount = data.interStateInd ? data.interStateAmcAmount : null;
    program.intraStateAmcAmount = data.intraStateInd ? data.intraStateAmcAmount : null;
    program.canadianAmcAmount = data.canadianInd ? data.canadianAmcAmount : null;
    program.exceedToPayInd = data.exceedToPayInd;
    program.commonRate = data.commonRate;
    program.programComment = data.programComment;
    return program;
  }

  requestAgreementList(): Observable<CustomerAgreement[]> {
    this.queryParamsCache = this.queryParams;
    return this.obiApiService.listCustomerAgreements(this.queryParams).pipe(
      map((incentiveListResp: ListCustomerAgreementsResp) => {
        incentiveListResp.customerAgreements.forEach((element: any) => {
          const daysToExpire = this.renderExpireDate(element.agreement);
          element.obiAgreementId = element.agreement.obiAgreementId;
          element.expireSoon = daysToExpire && daysToExpire < 31 ? Util.yes : Util.no;
          element.expired = !daysToExpire;
          element.expireDays = daysToExpire;
          element.effectiveDate = element.agreement.effectiveDate;
          element.expiryDate = element.agreement.expiryDate;
          element.agreement.customerName = this.helperService.removeExtraWhiteSpaces(element.agreement.customerName);
          element.address = element.address ? element.address : {};
          element.address.cityName = this.helperService.removeExtraWhiteSpaces(element.address.cityName);
        });
        return incentiveListResp.customerAgreements;
      })
    );
  }

  private renderExpireDate(input: any): any {
    const firstDate = moment(new Date());
    const secondDate = moment(input.expiryDate);
    const difference = secondDate.diff(firstDate, 'days');
    return !isNaN(difference) && difference >= 0 ? difference + 1 : Util.emptyString;
  }

  getQueryParameters(): ListCustomerAgreementsQuery {
    return this.queryParams;
  }

  getFilters(): any {
    if (!this.activeFilters) {
      this.filters = new ListCustomerAgreementsQuery();
    }
    this.activeFilters = false;
    return this.filters;
  }

  setQueryParameters(query: ListCustomerAgreementsQuery): any {
    this.queryParams = query;
    this.filters = query;
    this.periodBeginDate = this.queryParams.periodBeginDate;
    this.periodEndDate = this.queryParams.periodEndDate;
  }

  clearQueryParameter(): any {
    this.queryParams = new ListCustomerAgreementsQuery();
  }

  getAgreements(): Observable<CustomerAgreement[]> {
    // if (!this.obiAgreementListCache$ || this.queryParamsCache !== this.queryParams) {
    this.obiAgreementListCache$ = this.requestAgreementList().pipe(shareReplay());
    // }
    return this.obiAgreementListCache$;
  }

  requestAgreement(obiAgreementId): Observable<GetCustomerAgreementResp> {
    const pathParams = new GetCustomerAgreementPath();
    pathParams.obiAgreementId = obiAgreementId;
    return this.obiApiService.getCustomerAgreement(pathParams).pipe(
      map((response) => {
        const effDt = this.formatDateService.transformDate(response.customerAgreement.agreement.effectiveDate);
        const exprDt = this.formatDateService.transformDate(response.customerAgreement.agreement.expiryDate);
        response.customerAgreement.agreement.agreementComment =
          response.customerAgreement.agreement.agreementComment || '';
        response.customerAgreement.agreement.expiryDate = exprDt;
        response.customerAgreement.agreement.effectiveDate = effDt;
        return response;
      })
    );
  }

  requestAgreementHistory(customerCd): Observable<Incentive[]> {
    const pathParams = new ListCustomerIncentiveHistoryPath();
    pathParams.madCode = customerCd;
    const queryParams = new ListCustomerIncentiveHistoryQuery();

    return this.obiApiService.listCustomerIncentiveHistory(pathParams, queryParams).pipe(
      map((response: ListCustomerIncentiveHistoryResp) => {
        return response.incentives;
      })
    );
  }

  requestAgreementLocations(obiAgreementId): Observable<ListCustomerAgreementLocationsResp> {
    const pathParams = new GetCustomerAgreementPath();
    if (obiAgreementId) {
      pathParams.obiAgreementId = obiAgreementId;
      this.agreementLocationCache = this.obiApiService.listCustomerAgreementLocations(pathParams);
    }
    return this.agreementLocationCache;
  }

  requestAgreementProgramLocations(obiProgramId): Observable<ListCustomerProgramLocationsResp> {
    const pathParams = new ListCustomerProgramLocationsPath();
    pathParams.obiProgramId = obiProgramId;
    return this.obiApiService.listCustomerProgramLocations(pathParams).pipe(
      map((response: ListCustomerProgramLocationsResp) => {
        response.customerProgramLocations.forEach((item) => {
          if (!item.programCustomerLocation.discountRate) {
            item.programCustomerLocation.discountRate = 0;
          }
        });
        return response;
      })
    );
  }

  requestProgramDetails(obiProgramId): Observable<GetProgramResp> {
    const pathParams = new GetProgramPath();
    pathParams.obiProgramId = obiProgramId;
    return this.obiApiService.getProgram(pathParams);
  }

  createAgreement(agreement: Agreement) {
    const request = new CreateAgreementRqst();
    request.agreement = agreement;
    const dataOption = { toastOnError: false };
    return this.obiApiService.createAgreement(request, dataOption);
  }

  updateAgreement(agreementId: number, agreement: Agreement, actionType: string) {
    const request = new UpdateAgreementRqst();
    request.agreement = agreement;
    request.actionType = actionType;
    const pathParams = new UpdateAgreementPath();
    pathParams.obiAgreementId = agreementId;
    const dataOption = { toastOnError: false };
    return this.obiApiService.updateAgreement(request, pathParams, dataOption).pipe(
      map((response) => {
        const exprDt = this.formatDateService.transformDate(response.agreement.expiryDate);
        response.agreement.expiryDate = exprDt;
        return response;
      })
    );
  }

  deleteAgreement(agreementId: number) {
    const pathParams = new DeleteAgreementPath();
    pathParams.obiAgreementId = agreementId;
    const dataOption = { toastOnError: false };
    return this.obiApiService.deleteAgreement(pathParams, dataOption);
  }

  createProgram(obiGreementId: number, program: Program) {
    const request = new CreateProgramRqst();
    request.obiAgreementId = obiGreementId;
    request.program = program;
    const dataOption = { toastOnError: false };
    return this.obiApiService.createProgram(request, dataOption);
  }

  deleteProgram(programId: number) {
    const pathParams = new DeleteProgramPath();
    pathParams.obiProgramId = programId;
    const dataOption = { toastOnError: false };
    return this.obiApiService.deleteProgram(pathParams, dataOption);
  }

  updateProgram(program: Program, actionType: string) {
    const request = new UpdateProgramRqst();
    request.program = program;
    request.actionType = actionType;
    const pathParams = new UpdateProgramPath();
    pathParams.obiProgramId = program.obiProgramId;
    const dataOption = { toastOnError: false };
    return this.obiApiService.updateProgram(request, pathParams, dataOption);
  }

  updateProgramLocations(program: Program, programLocations: Location[]) {
    const request: BulkCreateProgramCustomerLocationsRqst = new BulkCreateProgramCustomerLocationsRqst();
    request.programCustomerLocations = this.convertLocationToProgramCustomerLocation(programLocations, program);
    const pathParams: BulkCreateProgramCustomerLocationsPath = new BulkCreateProgramCustomerLocationsPath();
    pathParams.obiProgramId = program.obiProgramId;
    const dataOption = { toastOnError: false, loadingOverlayEnabled: true };
    return this.obiApiService.bulkCreateProgramCustomerLocations(request, pathParams, dataOption);
  }

  convertLocationToProgramCustomerLocation(locations: Location[], program: Program): ProgramCustomerLocation[] {
    const pcLocation: ProgramCustomerLocation[] = locations.map((value: Location) => {
      const pcl = new ProgramCustomerLocation();
      pcl.madCode = value.AccCode;
      pcl.effectiveDate = this.dateService.formatDatePicker(program.programEffectiveDate);
      pcl.expiryDate = this.dateService.formatDatePicker(program.programExpiryDate);
      pcl.discountRate = program.commonRate ? program.commonRate : 0;
      pcl.billMessageInd = true;
      return pcl;
    });
    return pcLocation;
  }

  backToAgreementListPage() {
    this.activeFilters = true;
    this.router.navigate([`${AppRoutes.OBI_AGREEMENT}/${AppRoutes.LIST_PAGE}`]);
  }

  mockBulkResults(): Observable<BulkProcessStatus[]> {
    return from([this.bulkResults].length ? [this.bulkResults] : []);
  }

  /**
   * Create a list of results from bulk process request
   *
   * @param results - response from request
   * @param notChunk - bulk process does not aggregate results
   *
   * @example
   * unset `notChunk` or set it as false, to aggregate values
   * from bulk process response, ie: progressbar handles
   * multiple request with chunked data
   */
  setBulkResults(results: BulkProcessStatus[], notChunk?: boolean) {
    this.bulkResults = notChunk ? results : [...this.bulkResults, ...results];
  }

  setObiIncentiveId(incentiveId) {
    this.obiIncentiveId = incentiveId;
  }

  getObiIncentiveId(): string | boolean {
    return !(this.obiIncentiveId === '0') ? this.obiIncentiveId : false;
  }

  setObiIncentiveNbr(incentiveNbr) {
    this.obiIncentiveNbr = incentiveNbr;
  }

  getObiIncentiveNbr(): string | boolean {
    return !(this.obiIncentiveNbr === '0') ? this.obiIncentiveNbr : false;
  }
}
