<xpo-shell class="app-Container" [routes]="userRoutes$ | async" [drawerClosedByDefault]="false" [versionNumber]="build">
  <xpo-header-app-name>Edge</xpo-header-app-name>
  <xpo-header-sub-app-name>{{ title }}</xpo-header-sub-app-name>

  <xpo-header-actions>
    <app-notification-menu *ngIf="populateAccountPopover()"> </app-notification-menu>
    <xpo-auth-user-profile
      ><h4 class="role-list">{{ roleList }}</h4></xpo-auth-user-profile
    >
  </xpo-header-actions>
  <xpo-shell-sidebar-actions>
    <div>
      <a [href]="releaseNotesURL" target="_blank">Release Notes</a>
    </div>
  </xpo-shell-sidebar-actions>
  <router-outlet></router-outlet>
</xpo-shell>

<app-notification></app-notification>
