import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { XpoDialogModule } from '@xpo-ltl/ngx-ltl-core/dialog';
import { NotAuthorizedComponent } from './not-authorized/not-authorized.component';

const dialogs = [
  // YOUR DIALOGS
  NotAuthorizedComponent,
];

@NgModule({
  declarations: [...dialogs],
  imports: [
    // MATERIAL OR NGX CORE COMPONENTS
    XpoDialogModule,
    MatIconModule,
  ],
  exports: [...dialogs],
  entryComponents: [...dialogs],
})
export class DialogModule {}
