import { Component } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Program } from '@xpo-ltl/sdk-offbillincentive';
import { AppRoutes } from '../../../enums/app-routes.enum';

interface LinkableProgram {
  name: string;
  url?: UrlTree;
}
@Component({
  selector: 'app-obi-incentive-programs-cell-renderer',
  templateUrl: './grid-programs-cell-renderer.component.html',
  styleUrls: ['./grid-programs-cell-renderer.component.scss'],
})
export class GridProgramsCellRendererComponent {
  programs: LinkableProgram[];
  agreementId: string;

  constructor(private router: Router) {}

  agInit(params) {
    this.programs = this.renderPrograms(params.valueFormatted.programs, params.valueFormatted.agreementId);
  }

  renderPrograms(programs: Program[], agreementId?): LinkableProgram[] {
    return programs.map((value: any) => {
      const programId = value.obiProgramId;
      return {
        name: value.programTypeCd,
        url: this.getProgramUrl(agreementId, programId),
      };
    });
  }

  getProgramUrl(agreementId?, programId?) {
    return !!agreementId && !!programId
      ? this.router.createUrlTree([
          AppRoutes.OBI_AGREEMENT,
          AppRoutes.PROGRAM_DETAILS,
          agreementId,
          programId,
          AppRoutes.PROGRAM_HDR,
        ])
      : undefined;
  }

  redirect(programUrl) {
    window.open(programUrl, '_blank');
  }
}
