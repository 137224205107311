export enum Util {
  app = 'OBI',
  dateFormat = 'MM/dd/yyyy',
  dateServiceFormat = 'YYYY-MM-DD',
  pageSize = 10000,
  emptyString = '',
  shortYes = 'Y',
  shortNo = 'N',
  shortPaid = 'P',
  shortUnpaid = 'U',
  yes = 'Yes',
  no = 'No',
  undefined = 'undefined',
  unknown = 'UNKNOWN',
  rootCauseLocation = 'rootCause',
  PST_TIMEZONE = 'America/Los_Angeles',
  DATE_FORMAT_TIME = 'MM/DD/YYYY HH:mm z',
  STATE_COMPONENT = 'StateComponent',
  MAX_ALLOWED_EMAIL_ITEMS = 4,
  US = 'US',
  gross = 'G',
  net = 'N',
  accessorial = 'A',
  totalCharge = 'T',
  totalRevenue = 'R',
  fuelSurcharge = 'F',
  created = 'Created',
  validated = 'Validation errors found',
  ok = 'Ok',
  serverError = 'Server error',
  notFound = 'Not found',
  timeout = 30000,
  all = 'ALL',
  cwt = 'CWT',
  failCd = 'FAIL',
  retry = 'RETRY_CREATE',
  success = 'SUCCESS',
  formSent = 'SENT',
  formUnsent = 'UNSENT',
  bgWarning = 'bg-warning',
  bgDanger = 'bg-danger',
  bgSuccess = 'bg-success',
  bgProgress = 'bg-progress',
  textWarning = 'text-warning',
  textDanger = 'text-danger',
  textSuccess = 'text-success',
  textDark = 'text-dark',
  print = 'print',
  printDisabled = 'print_disabled',
  ascendent = 'asc',
  descendent = 'desc',
  CHUNK_SIZE = 50,
  rejectedCd = 'R',
}
