export enum ProgramFields {
  programTypeCd = 'Program Type',
  programEffectiveDate = 'Pgm Eff Date',
  programExpiryDate = 'Pgm Exp Date',
  renewProgramInd = 'Renew Program',
  commonRate = 'Common Rate',
  combinedLocationInd = 'Combine Locs',
  aggregatePeriodCd = 'Aggregation Period',
  payOnCd = 'Pay On',
  interStateInd = 'Inter State',
  interStateAmcAmount = 'Inter State AMC',
  qualifyOnCd = 'Qualify On',
  intraStateInd = 'Intra State',
  intraStateAmcAmount = 'Intra State AMC',
  rateTypeCd = 'Rate Type',
  canadianInd = 'International',
  canadianAmcAmount = 'International AMC',
  outboundPrepaidCd = 'OTBD/PPD',
  outboundPrepaidRate = 'OTBD/PPD Rate',
  outboundCollectCd = 'OTBD/COL',
  outboundCollectRate = 'OTBD/COL Rate',
  inboundPrepaidCd = 'INBD/PPD',
  inboundPrepaidRate = 'INBD/PPD Rate',
  inboundCollectCd = 'INBD/COL',
  inboundCollectRate = 'INBD/COL Rate',
  billtoPrepaidCd = 'B2PPD',
  billtoPrepaidRate = 'B2PPD Rate',
  billtoCollectCd = 'B2COL',
  billtoCollectRate = 'B2COL Rate',
  thirdPartyCd = '3RD Party',
  thirdPartyRate = '3RD Party Rate',
  spottedTrailerInd = 'Spotted Trailers Only',
  maxDiscountRevenueAmount = 'Max Disc Rev',
  maxDiscountPercentage = 'Max Disc Pct',
  maxDiscountWeightLbs = 'Max Disc Wt',
  minimumDiscountWeightLbs = 'Min Wt',
  verifyShipmentPaidInd = 'Verify Shipment Paid',
  exceedToPayInd = 'Exceed to Pay',
  maxDaysToPay = 'Max Days To Pay',
  minimumLimitNbr = 'Min Limit',
  maximumLimitNbr = 'Max Limit',
  tierTypeCd = 'Tier Type',
  rateAppliesToCd = 'Rate Applies To',
  discountRate = 'Discount Rate',
  amcApplicationTypeCd = 'Type',
  originStateCd = 'From State',
  destinationStateCd = 'To State',
  absoluteMinimumChargeAmount = 'AMC Amount',
}
