import { Injectable } from '@angular/core';
import { XpoBoardData, XpoBoardDataSource, XpoBoardState } from '@xpo-ltl/ngx-ltl-board';
import { BulkProcessStatus } from '@xpo-ltl/sdk-offbillincentive';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ObiLocationBulkXrtService } from './obi-location-bulk-xrt.service';

@Injectable({
  providedIn: 'root',
})
export class ObiLocationBulkDataSourceService extends XpoBoardDataSource {
  constructor(protected xrtService: ObiLocationBulkXrtService) {
    super();
  }

  fetchData(state: XpoBoardState): Observable<XpoBoardData> {
    const source = this.xrtService.search(BulkProcessStatus, {
      filter: state.criteria,
      pageNumber: state.pageNumber,
      pageSize: this.pageSize,
      sortExpressions: state.sortOrder,
    });
    return source.pipe(
      map((resp) => {
        return new XpoBoardData(state, resp.items, resp.resultCount, this.pageSize);
      }),
    );
  }
}
