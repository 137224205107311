export enum ColDef {
  w60 = 60,
  w100 = 100,
  w110 = 110,
  w120 = 120,
  w140 = 140,
  w150 = 150,
  w180 = 180,
  w200 = 200,
  w250 = 250,
}
