import { Incentive } from '@xpo-ltl/sdk-offbillincentive';

export class NotificationMenuList {
  notificationItems: NotificationMenuItem[];

  constructor() {
    this.notificationItems = [];
  }
}

export class NotificationMenuItem {
  title: string;
  emphasis: string;
  description: string;
  divider: string;
  canRetry?: boolean;
  data?: any;
  cssClass?: string;
  runStatusCd?: string;

  constructor(
    title: string,
    emphasis: string,
    description: string,
    divider: string,
    canRetry?: boolean,
    data?: any,
    cssClass?: string,
    runStatusCd?: string,
  ) {
    this.title = title;
    this.emphasis = emphasis;
    this.description = description;
    this.divider = divider;
    this.canRetry = canRetry;
    this.data = data;
    this.cssClass = cssClass;
    this.runStatusCd = runStatusCd;
  }
}
