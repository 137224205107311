<xpo-board class="shared-component" [dataSource]="resultDataSource" [views]="resultViews" [viewTemplates]="resultViewTemplates" [boardOptions]="boardOptions">
    <xpo-board-header class="shared-component__header">
        <xpo-board-header-title>
            <mat-icon>view_module</mat-icon>Results
        </xpo-board-header-title>
    </xpo-board-header>


    <xpo-board-actions>
        <button mat-button class="app-bulk-result__header__board-actions align-button" (click)="downloadExcel(gridType + '-result-list')" matTooltip="Download as Excel" [disabled]="!enableDownload">
            <xpo-icon iconName="download"></xpo-icon>
        </button>
    </xpo-board-actions>

    <xpo-ag-grid-board rowModelType="client-side" [enableSorting]="true" enableMovableColumns="true" [customGridOptions]="resultGridOptions" (gridReady)="onGridReady($event)">
    </xpo-ag-grid-board>
</xpo-board>
