import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'currency-cell-renderer',
  templateUrl: './currency-cell-renderer.component.html',
  styleUrls: ['./currency-cell-renderer.component.scss'],
})
export class CurrencyCellRendererComponent {
  params: ICellRendererParams;

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  shouldDisplayValue(): boolean {
    return (this.params.node.group && this.params.column.getAggFunc() != null) || !this.params.node.group;
  }
}
