import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { RegionInfo, XpoLtlAuthenticationService } from '@xpo-ltl/ngx-auth';
import { XpoAppSwitcherApplication } from '@xpo-ltl/ngx-ltl-core/app-switcher-popover';
import { XpoShellRoute } from '@xpo-ltl/ngx-ltl-core/shell';
import { User } from '@xpo-ltl/sdk-common';
import { IncentiveWithBatchControl, ListIncentiveBatchControlsResp } from '@xpo-ltl/sdk-offbillincentive';
import { invoke as _invoke, isEmpty as _isEmpty } from 'lodash';
import { BehaviorSubject, interval, Observable } from 'rxjs';
import { delay, retryWhen, skipWhile, take, tap } from 'rxjs/operators';
import { NotificationMenuItem } from './models/notification-menu-item.model';
import { AppRoleService } from './services/app-role.service';
import { NotificationStateService } from './services/notification-state.service';
import { ObiApiService } from './services/obi-api.service';
import { AppActions } from './shared/enums/app-actions.enum';
import { AppRoutes } from './shared/enums/app-routes.enum';
import { ConfigManagerProperties } from './shared/enums/config-manager-properties.enum';
import { Menu } from './shared/enums/menu.enum';
import { Util } from './shared/enums/util.enum';

interface XpoShellRouteSecure extends XpoShellRoute {
  permission?: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
  constructor(
    private configManagerService: ConfigManagerService,
    private appRoleService: AppRoleService,
    private obiService: ObiApiService,
    private authService: XpoLtlAuthenticationService,
    private notificationStateService: NotificationStateService
  ) {
    const region = this.configManagerService.getSetting<string>(ConfigManagerProperties.region);
    this.authService.initAuthSetup$(region).subscribe((info: RegionInfo) => {});

    /** Shell setup */
    this.build = configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion);

    this.handleLoggedInUser();

    this.appRoleService.enabledActionObs.subscribe((resp) => {
      const permittedRoutes = this.getUserRoutes(this.routes);
      this.userRoutes.next(permittedRoutes);
    });
  }
  readonly releaseNotesURL: string =
    'https://xpologistics.sharepoint.com/:f:/r/sites/150/projects/LTLOBI/Monitor%20and%20Control/Release%20Notes?csf=1&web=1&e=oy1LyM';
  readonly title: string = Util.app;
  roleList: string;
  build: string;
  apps$: Observable<XpoAppSwitcherApplication[]>;
  defaultSic = 'UPO';
  notifications: NotificationMenuItem[];
  notificationInterval;
  userRoutes = new BehaviorSubject<XpoShellRoute[]>([]);
  userRoutes$ = this.userRoutes.asObservable();
  routes: XpoShellRouteSecure[] = [
    {
      label: Menu.offBillIncentiveList,
      path: `/${AppRoutes.OFF_BILL_INCENTIVE}/${AppRoutes.LIST_PAGE}`,
      permission: AppActions.incentive_list,
    },
    {
      label: Menu.agreementList,
      path: `/${AppRoutes.OBI_AGREEMENT}/${AppRoutes.LIST_PAGE}`,
      permission: AppActions.agreement_list,
    },
    {
      label: Menu.newIncentive,
      path: `/${AppRoutes.OFF_BILL_INCENTIVE}/${AppRoutes.LIST_PAGE}/${AppRoutes.ADD}`,
      permission: AppActions.incentive_add,
    },
  ];

  private getUserRoutes(routes: XpoShellRouteSecure[]): XpoShellRoute[] {
    return routes
      .filter((route: XpoShellRouteSecure) => {
        return this.appRoleService.getEnableActionByCode(route.permission);
      })
      .map((route: XpoShellRouteSecure) => {
        return {
          label: route.label,
          path: route.path,
        };
      });
  }

  private handleLoggedInUser(): void {
    this.obiService.offBillIncentiveApiService
      .loggedInUser()
      .pipe(retryWhen((errors) => errors.pipe(delay(1000), take(5))))
      .subscribe(
        (user: User) => {
          if (user) {
            // user.roles
            this.appRoleService.setUser(user);
            // this.populateAccountPopover();
            this.setDynatraceUserIdentity(user);
          }

          this.getNotifications();

          if (this.notificationInterval) {
            clearInterval(this.notificationInterval);
          }
          this.notificationInterval = setInterval(this.getNotifications, Util.timeout);
          this.appRoleService.getRoleName().subscribe((data) => {
            this.roleList = this.formatRoleList(data);
          });
        },
        (error) => {
          console.log('ERROR', error);
        }
      );
  }

  private formatRoleList(roles: string[]) {
    return roles.reduce((acc, curr, index, list) => {
      return (acc += index === list.length - 1 ? curr : curr + ' - ');
    }, '');
  }

  populateAccountPopover(): boolean {
    return this.appRoleService.getEnableActionByCode(AppActions.incentive_add);
  }

  private getNotifications = () => {
    this.obiService.getBatchIncentives().subscribe((response: ListIncentiveBatchControlsResp) => {
      this.notifications = [];
      response.incentiveWithBatchControls.forEach((element: IncentiveWithBatchControl) => {
        this.notifications.push(
          new NotificationMenuItem(
            element.incentive.incentiveNbr.toString(),
            element.incentive.madCode,
            element.incentive.customerNameOnCheck,
            '-',
            element.incentiveBatchControl.runStatusCd === Util.failCd,
            element.incentive,
            undefined,
            element.incentiveBatchControl.runStatusCd
          )
        );
      });
      this.notificationStateService.setNotifications(this.notifications);
    });
  };

  private setDynatraceUserIdentity(user: User): void {
    const setUser = (): void =>
      _invoke(window['dtrum'], 'identifyUser', !_isEmpty(user.emailAddress) ? user.emailAddress : user.userId);
    if ((window['dtrum'] || {})['identifyUser']) {
      setUser();
    } else {
      let retryCount: number = 0;
      interval(1000)
        .pipe(
          tap(() => retryCount++),
          skipWhile(() => !(window['dtrum'] || {})['identifyUser'] && retryCount <= 60),
          take(1)
        )
        .subscribe(() => {
          setUser();
        });
    }
  }
}
