/**
 * Actions
 */
export enum AppActions {
  // Url permission
  agreement_list = 'Agreement_list',
  agreement_detail = 'Agreement_detail',
  program_detail = 'Program_detail',
  agreement_history = 'Agreement_history',
  agreement_location = 'Agreement_location',
  program_location = 'Program_location',
  incentive_list = 'Incentive_list',
  incentive_detail = 'Incentive_detail',
  incentive_list_location = 'Incentive_list_location',
  incentive_shipment_list = 'Incentive_shipment_list',
  // Agreement options
  agreement_add = 'Agreement_add',
  agreement_expire = 'Agreement_expire',
  agreement_backdate = 'Agreement_backdate',
  agreement_delete = 'Agreement_delete',
  agreement_change = 'Agreement_change',
  agreement_renew = 'Agreement_renew',
  agreement_extend = 'Agreement_extend',
  agreement_email_add = 'Agreement_email_add',
  agreement_email_update = 'Agreement_email_update',
  program_add = 'Program_add',
  program_delete = 'Program_delete',
  program_change_rate = 'Program_change_rate',
  program_expire = 'Program_expire',
  program_backdate = 'Program_backdate',
  program_bill_message = 'Program_bill_message',
  program_change = 'Program_change',
  program_location_delete = 'Program_location_delete',
  program_location_expire = 'Program_location_expire',
  program_location_backdate = 'Program_location_Backdate',
  program_location_add = 'Program_location_add',
  program_location_change_rate = 'Program_location_Change_Rate',
  // Incentive options
  incentive_update = 'Incentive_update',
  incentive_delete = 'Incentive_delete',
  incentive_deny = 'Incentive_deny',
  incentive_approve = 'Incentive_approve',
  incentive_add = 'Incentive_add',
  incentive_print = 'Incentive_print',
  incentive_reset = 'Incentive_reset',
  incentive_recalc = 'Incentive_recalc',
  incentive_adjusted_rate_program = 'Incentive_adjusted_rate_program',
  incentive_adjusted_rate_rate = 'Incentive_adjusted_rate_rate',
  incentive_shipment_discount = 'Incentive_shipment_discount',
  incentive_shipment_pending = 'Incentive_shipment_pending',
  incentive_shipment_rejected = 'Incentive_shipment_rejected',
  incentive_shipment_reprocess = 'Incentive_shipment_reprocess',
  incentive_shipment_adjusted_rate = 'Incentive_shipment_adjusted_rate',
  incentive_shipment_adjusted_discount = 'Incentive_shipment_adjusted_discount',
  incentive_shipment_vip_adjusted_rate = 'Incentive_shipment_VIP_adjusted_rate',
  incentive_list_location_adjust_discount = 'Incentive_list_location_adjust_Discount',
  incentive_shipment_bulk_update_shipments = 'Incentive_shipment_Bulk_Update_Shipments',
}
