import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { MatTooltipModule } from '@angular/material/tooltip';

import { XpoNgxGridModule } from '@xpo-ltl/ngx-grid';
import { XpoBoardModule } from '@xpo-ltl/ngx-ltl-board';
import { XpoAgGridBoardModule } from '@xpo-ltl/ngx-ltl-board-grid';
import { XpoChipsModule } from '@xpo-ltl/ngx-ltl-core/chips';
import { XpoIconModule } from '@xpo-ltl/ngx-ltl-core/icon';
import { BooleanCellRendererComponent } from './cell-renderers/boolean-cell-renderer/boolean-cell-renderer.component';
import { CurrencyCellRendererComponent } from './cell-renderers/currency-cell-renderer/currency-cell-renderer.component';
import { StatusCellRendererComponent } from './cell-renderers/status-cell-renderer/status-cell-renderer.component';
import {
  ActionBarCenterContent,
  ActionBarComponent,
  ActionBarLeftContent,
  ActionBarRightContent,
} from './components/action-bar/action-bar.component';
import { BulkResultComponent } from './components/bulk-result/bulk-result.component';
import { BaselineGridApiComponent } from './components/grid/baseline-grid-api/baseline-grid-api.component';
import { GridProgramsCellRendererComponent } from './components/grid/obi-incentive-programs-cell-renderer/grid-programs-cell-renderer.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { ButtonStateDirective } from './directives/button-state.directive';
import { XpoStatusIndicatorModule } from '@xpo-ltl/ngx-ltl-core/status-indicator';

@NgModule({
  declarations: [
    // Components
    ActionBarComponent,
    ActionBarLeftContent,
    ActionBarCenterContent,
    ActionBarRightContent,
    BulkResultComponent,
    ProgressBarComponent,
    ButtonStateDirective,
    GridProgramsCellRendererComponent,
    BaselineGridApiComponent,
    CurrencyCellRendererComponent,
    BooleanCellRendererComponent,
    StatusCellRendererComponent,
    // Pipes/Directives
  ],
  imports: [
    CommonModule,
    XpoAgGridBoardModule,
    XpoBoardModule,
    XpoIconModule,
    MatMenuModule,
    MatProgressBarModule,
    MatChipsModule,
    XpoChipsModule,
    XpoNgxGridModule,
    XpoStatusIndicatorModule,
    MatTooltipModule,

    // Pipes/Directives
  ],
  exports: [
    // Components
    ActionBarComponent,
    ActionBarLeftContent,
    ActionBarCenterContent,
    ActionBarRightContent,
    BulkResultComponent,
    ProgressBarComponent,
    GridProgramsCellRendererComponent,
    // Pipes/Directives
    ButtonStateDirective,
    BaselineGridApiComponent,
  ],
  // providers:
})
export class SharedModule {}
