export enum AppRoutes {
  LANDING_PAGE = 'landing-page',
  NOT_AUTHORIZED_PAGE = 'not-authorized',
  OFF_BILL_INCENTIVE = 'off-bill-incentive',
  OBI_AGREEMENT = 'obi-agreement',
  LIST_PAGE = 'list-page',
  DETAIL_PAGE = 'detail-page',
  INCENTIVE_HDR = 'incentive-hdr',
  LOCATIONS = 'locations',
  INCENTIVE_SHIPMENTS = 'incentive-shipment',
  AGREEMENT_HDR = 'agreement-hdr',
  AGREEMENT_HISTORY = 'agreement-history',
  AGREEMENT_LOCATIONS = 'agreement-locations',
  PROGRAM_DETAILS = 'program-details',
  PROGRAM_HDR = 'program-hdr',
  PROGRAM_LOCATIONS = 'program-locations',
  AGREEMENT_CREATE = 'agreement-create',
  PROGRAM_CREATE = 'program-create',
  ADD = 'add',
}
