import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { XpoGridConfig, XpoGridReadyEvent } from '@xpo-ltl/ngx-grid';
import { XpoAgGridBoardColumn } from '@xpo-ltl/ngx-ltl-board-grid';
import { GridApi, GridOptions } from 'ag-grid-community';

@Component({
  selector: 'app-baseline-grid-api',
  templateUrl: './baseline-grid-api.component.html',
  styleUrls: ['./baseline-grid-api.component.scss'],
})
export class BaselineGridApiComponent implements OnInit, OnChanges {
  gridApi: GridApi;

  private rowData: any[] = undefined;

  private columDefs: XpoAgGridBoardColumn[] = undefined;

  gridConfig: XpoGridConfig = {
    gridSettingsParams: { keyField: 'test' },
    gridOptions: {
      rowData: this.rowData,
      columnDefs: this.columDefs,
    },
  };

  gridOptions: GridOptions;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}

  onGridReady(event: XpoGridReadyEvent) {
    this.gridApi = event.gridRef.gridOptions.api;
    this.gridOptions = event.gridRef.gridOptions;
  }
}
