import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorDialogComponent } from '../../../shared/dialog/error-dialog';

@Component({
  selector: 'app-location-dialog',
  templateUrl: './result-dialog.component.html',
  styleUrls: ['./result-dialog.component.scss'],
})
export class ResultDialogComponent implements OnInit {
  bulkResult = [];

  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<ResultDialogComponent>,
  ) {
    this.bulkResult = data.result;
  }

  ngOnInit() {}

  cancel() {
    this.dialogRef.close();
  }

  openMaxWidthDialog(error: any): void {
    this.dialog.open(ErrorDialogComponent, { maxWidth: '600px', data: error });
  }
}
