import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  template: `
    <h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>{{ title }}</h1>
    <div mat-dialog-content>
      {{ message }}
    </div>
    <div mat-dialog-actions>
      <button type="button" mat-stroked-button>Close</button>
    </div>
  `,
  styles: [''],
})
export class ConfirmDialogComponent {
  title: string = '';
  message: string = '';

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>, @Inject(MAT_DIALOG_DATA) public data) {
    this.title = data.title;
    this.message = data.message;
  }

  confirm() {
    this.dialogRef.close();
  }
}
