import { Component, OnDestroy, OnInit } from '@angular/core';
import { NotificationService } from '@xpo-ltl/data-api';
import {
  XpoBoardOptions,
  XpoBoardState,
  XpoDateOnlyFilterComponent,
  XpoEnumFilterComponent,
  XpoNumberFilterComponent,
  XpoStringFilterComponent,
} from '@xpo-ltl/ngx-ltl-board';
import { XpoAgGridBoardColumn, XpoAgGridBoardView, XpoAgGridBoardViewTemplate } from '@xpo-ltl/ngx-ltl-board-grid';
import { Column, ColumnApi, ColumnMovedEvent, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { ReplaySubject } from 'rxjs';
import { FileExporterService } from '../../../services/file-exporter.service';
import { ObiLocationBulkDataSourceService } from '../../../services/obi-location-bulk-data-source.service';
import { ColDef } from '../../enums/col-def.enum';
import { Util } from '../../enums/util.enum';
import { ContextGridService } from '../../services/grid/context-grid';

@Component({
  selector: 'app-bulk-result',
  templateUrl: './bulk-result.component.html',
  styleUrls: ['./bulk-result.component.scss'],
})
export class BulkResultComponent implements OnInit, OnDestroy {
  gridApi: GridApi;
  columnApi: ColumnApi;
  excelData: String | Blob;
  enableDownload: boolean = false;
  resultGridOptions: GridOptions = <GridOptions>{
    defaultColDef: {
      menuTabs: ['filterMenuTab'],
      icons: {
        menu: `<mat-icon class="material-icons" style="font-size: 18px;">filter_list</mat-icon>`,
      },
    },
    frameworkComponents: {
      xpoStringFilterComponent: XpoStringFilterComponent,
      xpoNumberFilterComponent: XpoNumberFilterComponent,
      xpoDateOnlyFilterComponent: XpoDateOnlyFilterComponent,
      xpoEnumFilterComponent: XpoEnumFilterComponent,
    },
    onColumnMoved: (event: ColumnMovedEvent) => {
      this.gridApi.setColumnDefs(event.api.getColumnDefs());
    },
  };
  boardOptions: XpoBoardOptions = {
    preloadViewData: true,
  };
  resultViewTemplates: XpoAgGridBoardViewTemplate[];
  resultViews: XpoAgGridBoardView[];
  gridType: string;
  stateChange$ = new ReplaySubject<XpoBoardState>(1);

  constructor(
    public resultDataSource: ObiLocationBulkDataSourceService,
    private contextGridService: ContextGridService,
    private notificationService: NotificationService,
    private fileExporter: FileExporterService,
  ) {
    this.resultDataSource.pageSize = Util.pageSize;
    this.gridType = this.contextGridService.getGridType();
    this.resultViewTemplates = this.getResultBoardViewTemplates();
    this.resultViews = this.getResultBoardViews(this.resultViewTemplates[0]);
  }

  ngOnInit(): void {
    this.resultDataSource.connect(this).subscribe((status) => {
      this.enableDownload = status.data?.recordCount > 0;
    });
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
  }

  private getResultBoardViews(viewTemplate: XpoAgGridBoardViewTemplate): XpoAgGridBoardView[] {
    let contextualViews = [] as XpoAgGridBoardView[];

    contextualViews = [
      viewTemplate.createView({
        id: 'error-list',
        name: 'Error',
        criteria: {
          bulkProcessStatus: [400, 404, 500],
        },
      }),
      viewTemplate.createView({
        id: 'successful-list',
        name: 'Success',
        criteria: {
          bulkProcessStatus: [200, 201],
        },
      }),
    ];

    return [
      viewTemplate.createView({
        id: 'result-list',
        name: `Result List`,
      }),
      ...contextualViews,
    ];
  }

  private getResultBoardViewTemplates(): XpoAgGridBoardViewTemplate[] {
    return [
      new XpoAgGridBoardViewTemplate({
        id: 'obi-location-result',
        name: 'Obi-Location-Result',
        availableColumns: this.getResultColumns(),
        keyField: 'bulkProcessId',
      }),
    ];
  }

  private getResultColumns() {
    let contextualColumns = [] as XpoAgGridBoardColumn[];

    switch (this.gridType) {
      case 'shipments':
        contextualColumns = [
          {
            headerName: 'Pro Number',
            field: 'bulkProNbr',
            width: ColDef.w120,
            resizable: true,
            filter: 'xpoStringFilterComponent',
          },
        ];
        break;
      case 'locations':
        contextualColumns = [
          {
            headerName: 'MadCd',
            field: 'bulkMadCode',
            width: ColDef.w120,
            resizable: true,
            filter: 'xpoStringFilterComponent',
          },
        ];
        break;
    }

    return [
      {
        headerName: 'Id',
        field: 'bulkProcessId',
        width: ColDef.w100,
        filter: 'xpoStringFilterComponent',
        valueFormatter: (params) => (params.value?.includes(Util.notFound) ? Util.notFound : params.value),
      },
      ...contextualColumns,
      {
        headerName: 'Status',
        field: 'bulkProcessStatus',
        maxWidth: 200,
        resizable: true,
        filter: 'xpoStringFilterComponent',
        valueGetter: (params) => this.getStatus(params.data.bulkProcessStatus),
      },
      {
        headerName: 'Description',
        field: 'bulkProcessMessages',
        resizable: true,
        minWidth: 400,
        filter: 'xpoStringFilterComponent',
        autoHeight: true,
        wrapText: true,
      },
    ];
  }

  getStatus(status: number): string {
    switch (status) {
      case 200:
        return Util.ok;
      case 201:
        return Util.created;
      case 500:
        return Util.serverError;
      case 404:
        return Util.notFound;
      default:
        return Util.validated;
    }
  }

  downloadExcel(name) {
    this.notificationService.showOverlayMessage();

    if (this.columnApi) {
      const columns: Column[] = this.columnApi.getAllColumns();
      this.excelData = this.buildExcelData(columns);
    }

    this.fileExporter.setMimeType('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    this.fileExporter.setData(this.excelData);

    this.fileExporter.download(name).subscribe({
      next: (file) => this.fileExporter.save(file.name),
      complete: () => this.notificationService.hideOverlayMessage(),
    });
  }

  buildExcelData(columns: Column[]): string | Blob {
    if (!this.gridApi) {
      return;
    }

    if (this.gridApi.getModel().getRowCount() > 0) {
      return this.gridApi.getDataAsExcel({
        columnKeys: columns,
      });
    }
  }

  ngOnDestroy(): void {
    this.contextGridService.setGridType(undefined);
  }
}
