<div class="mat-dialog-container__inner-wrapper" fxLayout="column">
    <div class="mat-dialog-container__close" fxLayout="row">
        <button mat-button class="close-icon" [mat-dialog-close]="true" style="justify-content: end;">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <app-bulk-result fxLayout="column"></app-bulk-result>

    <div mat-dialog-actions>
        <xpo-button-group></xpo-button-group>
        <button mat-stroked-button (click)="cancel()">Close</button>
    </div>
</div>