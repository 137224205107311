import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';

export interface Selection {
  key: string;
  value: string;
}

@Directive({
  selector: '[appButtonState]',
})
export class ButtonStateDirective implements OnChanges {
  @Input('selectedItem') selectedItem: Selection;
  @Input('displayKey') displayKey: boolean;
  @Input('displayValue') displayValue: boolean;
  @Input('buttonType') buttonType: string;
  stateElement: ElementRef;

  constructor(private renderer: Renderer2, private elementRef: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!Object.values(changes).some((item) => item.firstChange || !item.currentValue)) {
      this.handleUI();
    }
  }

  handleUI() {
    if (this.stateElement) {
      this.resetStateElement();
    }

    this.stateElement = this.createElement(this.selectedItem);
    this.renderer.addClass(this.elementRef.nativeElement, 'has-state');
    this.renderer.appendChild(this.elementRef.nativeElement, this.stateElement);
  }

  createElement(selection: Selection): ElementRef {
    const element = this.renderer.createElement('span');
    const textToDisplay = this.displayKey ? selection.key : this.displayValue ? selection.value : null;
    const text = this.renderer.createText(textToDisplay);

    this.renderer.addClass(element, 'state-indicator');
    this.renderer.appendChild(element, text);

    return element;
  }

  resetStateElement() {
    this.renderer.removeChild(this.elementRef, this.stateElement);
    this.renderer.removeClass(this.elementRef.nativeElement, 'has-state');
  }
}
