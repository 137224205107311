import { Injectable } from '@angular/core';
import { Program } from '@xpo-ltl/sdk-offbillincentive';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  constructor() {}

  // Needed to fix some field values that contains extra whitespaces
  // that affect the applied filter 'is exact' match criteria
  removeExtraWhiteSpaces(fieldData: string) {
    return fieldData ? fieldData.replace(/\s\s+/g, ' ').trim() : null;
  }

  // Needed to match the filterParams object, as the provided
  // enum does not makes the filter to work properly
  enumToFilterParams(enumObject: any) {
    const filterParams = {};
    Object.entries(enumObject).forEach(([key, val]) => {
      filterParams[enumObject[key]] = val;
    });
    return filterParams;
  }

  enumToKeyValueArray(enumObject: any) {
    return Object.entries(enumObject).reduce((values, [currKey, currVal]) => {
      const obj = { key: currKey, val: currVal };
      return [obj, ...values];
    }, []);
  }

  cloneObject(obj: any) {
    return JSON.parse(JSON.stringify(obj));
  }

  /**
   * @param seek  Object used to iterate over its properties.
   * @param match Object used to match values with seek property.
   * @param skip  List of properties of seek object that will be ignored.
   *
   * @returns `true` if objects are different otherwise `false`
   */
  compareObjects(seek: Object, match: Object, skip?: string[]): boolean {
    let changes: boolean;

    if (skip) {
      skip.forEach((prop: string) => {
        if (seek && seek[prop]) {
          delete seek[prop];
        }
      });
    }

    for (const [key, val] of Object.entries(seek)) {
      changes = match && match[key] !== val ? true : false;
      if (changes) {
        break;
      }
    }

    return changes;
  }

  formatProNumber(proNbr) {
    if (!proNbr) {
      return;
    }

    const isLongFormat = proNbr.charAt(0) === '0' && proNbr.charAt(4) === '0' ? true : false;

    return isLongFormat ? [proNbr.slice(1, 4), proNbr.slice(5)].join('') : proNbr;
  }

  setListOfProgramTypes(programs: Program[]) {
    return programs?.reduce((list: string, program, index, arr) => {
      const programTypeCd = program.programTypeCd;
      return !(index === arr.length - 1) ? (list += programTypeCd + ', ') : (list += programTypeCd);
    }, '');
  }

  addCustomEncoding(value: string | any) {
    const encoding = [
      { char: '&', code: '[AMP]' },
      { char: '#', code: '[NUM]' },
      { char: '+', code: '[PLUS]' },
    ];

    if (encoding.some((entry) => value.includes(entry.char))) {
      for (const entry of encoding) {
        value = value.replaceAll(entry.char, entry.code);
      }
    }

    return value;
  }
}
