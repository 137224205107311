import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  template: `
    <div class="progressbar-wrapper" *ngIf="displayProgressbar">
      <mat-progress-bar mode="determinate" value="{{ progress$ }}"></mat-progress-bar>
      <span
        >Completed <em>{{ progress$ }}%</em> with <em>{{ totalAdded$ }}</em> records.</span
      >
    </div>
  `,
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnChanges {
  @Input() progress$;
  @Input() totalAdded$;
  displayProgressbar: boolean = false;

  ngOnChanges(changes: SimpleChanges): void {
    const isFirstChange = Object.values(changes).some((change) => change.firstChange);

    this.toggleProgressBar(changes.progress$.currentValue);
  }

  toggleProgressBar(currentValue) {
    this.displayProgressbar = currentValue > 0 ? true : false;
  }
}
