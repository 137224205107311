import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProgramFields } from '../enums/program-fields.enum';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.html',
  styleUrls: ['./error-dialog.scss'],
})
export class ErrorDialogComponent {
  errorList = [];
  constructor(public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data) {
    if (data && data.error && data.error.moreInfo) {
      data.error.moreInfo.forEach((element) => {
        const location = element.location;
        const field = this.getFieldName(location);

        let message: string = `${element.message}`;

        if (field) {
          message += ` at ${field} field`;
        }

        this.errorList.push(message);
      });
    } else {
      this.errorList.push('Unexpected error');
    }
  }

  get dialogTitle() {
    return this.errorList.length > 1 ? 'Error List' : 'Error';
  }

  getFieldName(location) {
    const field = location?.split('.')[1];
    return ProgramFields[field];
  }
}
