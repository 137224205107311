import { Injectable, Type } from '@angular/core';
import { BulkProcessStatus } from '@xpo-ltl/sdk-offbillincentive';
import { XrtInMemoryService } from '@xpo/ngx-xrt';
import { Observable } from 'rxjs';
import { AgreementListService } from './agreement-list.service';

@Injectable({
  providedIn: 'root',
})
export class ObiLocationBulkXrtService extends XrtInMemoryService {
  constructor(agrementApiService: AgreementListService) {
    super(
      new Map<Type<any>, () => Observable<any[]>>([
        [BulkProcessStatus, agrementApiService.mockBulkResults.bind(agrementApiService)],
      ])
    );
  }
}
