import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ContextGridService {
  private gridType = undefined;

  setGridType(type: string) {
    this.gridType = type;
  }

  getGridType() {
    return this.gridType;
  }
}
